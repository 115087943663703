import React from 'react';


const MarketingCard = ({img,title,text,color}) => {
    return (
        <div class={`${color} text-white m-4 p-5 marketing-card shadow-2xl hover:scale-105 transition duration-500 cursor-pointer`}>
            <div className='p-4 flex gap-4 items-center'>
                <img src={img} style={{height:"80px"}} className='img-fluid' alt="Card" />
                <span>{title}</span>
            </div>
            <p className='py-1'>{text}</p>
        </div>
    );
};

export default MarketingCard;