import logo from './logo.svg';
import './App.css';
import Home from './page/home/Home';
import { Route, Routes, useLocation } from 'react-router-dom';
import MarketingConsultant from './page/MarketingConsultant/MarketingConsultant';
import 'flowbite';
import Blog from './component/Blog/Blog';
import BlogPage from './page/BlogPage/BlogPage';
import ContactPage from './page/ContactPage/ContactPage';
import { useEffect } from 'react';
import { initFlowbite } from 'flowbite';

function App() {

  const location = useLocation();

  useEffect(() => {
    // Reinitialize Flowbite JS components on every route change
    initFlowbite();
  }, [location]);
  
  return (


        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/marketing-consultant" element={<MarketingConsultant />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>

  );
}

export default App;
