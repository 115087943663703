import React from 'react';

const ServiceCard = ({CardImage,title,text}) => {
    return (
        <>
            <div class={`bg-[#F9EFEE] m-4 p-5 marketing-card shadow-xl transform transition duration-500 hover:scale-105 cursor-pointer`}>
                <div className='p-4 flex gap-4 items-center text-cardBG font-bold'>
                    <img src={CardImage} style={{ height: "80px" }} className='img-fluid' alt="Card" />
                    <span>{title}</span>
                </div>
                <p className='p-3 text-gray-400'>{text}</p>
            </div>
        </>
    );
};

export default ServiceCard;