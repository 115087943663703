import React from 'react';
import Header from '../../component/Header/Header';
import OnlineBusiness from '../../component/OnlineBusiness/OnlineBusiness';
import MarketingBrand from '../../component/MarketingBrand/MarketingBrand';
import Testimonials from '../../component/Testimonials/Testimonials';
import OurProcess from '../../component/OurProcess/OurProcess';
import FrequentlyAskedQuestions from '../../component/FrequentlyAskedQuestions/FrequentlyAskedQuestions';
import Blog from '../../component/Blog/Blog';
import Footer from '../../component/Footer/Footer';

const Home = () => {
    return (
        <>
             <Header />
             <section>
             <OnlineBusiness/>
             </section>
             <MarketingBrand/>
             <Testimonials/>
             <OurProcess/>
             <FrequentlyAskedQuestions/>
             <Blog/>
             <Footer/>
        </>
    );
};

export default Home;