
import React, { useRef } from 'react';
import Blog1 from "../../asset/image/blog/1.png"
import Blog2 from "../../asset/image/blog/2.png"
import Blog3 from "../../asset/image/blog/3.png"
import Blog4 from "../../asset/image/blog/4.png"
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import backgroundImage from '../../asset/image/background/FlagBG.png';

const Blog = () => {
    const sliderRef = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    const backgroundColor = {
        backgroundColor: "rgb(241 245 249)",
        opacity: "80%"
    }

    return (
        <div>
            <div style={backgroundColor} className='pt-5 my-20'>
                <div className="max-w-screen-2xl mx-auto px-3 py-10 relative">
                    <div>
                        <h2 className='text-4xl font-bold p-10'>Our Latest Blogs</h2>
                    </div>
                    <div className="flex justify-between items-center">


                        {/* Navigation Buttons */}
                        <div className="absolute top-0 right-0 mt-3">
                            <button
                                className="bg-white mx-5 hover:bg-gray-300 text-gray-800 font-bold py-2 px-2 rounded-full"
                                onClick={() => sliderRef.current.slickPrev()}
                            >
                                <svg class="h-8 w-8 text-[#586893]" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="15 6 9 12 15 18" /></svg>
                            </button>
                            <button
                                className="bg-white mx-5 hover:bg-gray-300 text-gray-800 font-bold py-2 px-2 rounded-full"
                                onClick={() => sliderRef.current.slickNext()}
                            >
                                <svg class="h-8 w-8 text-[#586893]" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <polyline points="9 6 15 12 9 18" /></svg>
                            </button>
                        </div>
                    </div>

                    {/* Slider Section */}
                    <Slider ref={sliderRef} {...settings}>
                        <div className='px-4 focus:outline-none focus:border-none focus:shadow-none py-5'>
                            <div class="col-span-12 md:col-span-3 px-9 shadow-md p-7 rounded-lg bg-white hover:scale-105 transition duration-500 cursor-pointer">
                                <img src={Blog1} className='w-full h-[200px] rounded-[25px]' alt="" />
                                <div className="p-1 pt-4">
                                    <h3 className='font-bold'>UI/UX Design Tips</h3>
                                    <p className='text-sm py-3'>Promotion World has once placed Page Traffic the top.</p>
                                    <a href="#" className='text-sm  underline font-bold'>Read Blogs</a>
                                </div>
                            </div>
                        </div>
                        <div className='px-4 focus:outline-none focus:border-none focus:shadow-none py-5'>
                            <div class="col-span-12 md:col-span-3 px-9 shadow-md p-7 rounded-lg bg-white hover:scale-105 transition duration-500 cursor-pointer">
                                <img src={Blog2} className='w-full h-[200px] rounded-[25px]' alt="" />
                                <div className="p-1 pt-4">
                                    <h3 className='font-bold'>Web solution</h3>
                                    <p className='text-sm py-3'>Promotion World has once placed Page Traffic the top.</p>
                                    <a href="#" className='text-sm  underline font-bold'>Read Blogs</a>
                                </div>
                            </div>
                        </div>
                        <div className='px-4 focus:outline-none focus:border-none focus:shadow-none py-5'>
                            <div class="col-span-12 md:col-span-3 px-9 shadow-md p-7 rounded-lg bg-white hover:scale-105 transition duration-500 cursor-pointer">
                                <img src={Blog3} className='w-full h-[200px] rounded-[25px]' alt="" />
                                <div className="p-1 pt-4">
                                    <h3 className='font-bold'>Web solution</h3>
                                    <p className='text-sm py-3'>Promotion World has once placed Page Traffic the top.</p>
                                    <a href="#" className='text-sm  underline font-bold'>Read Blogs</a>
                                </div>
                            </div>
                        </div>

                        <div className='px-4 focus:outline-none focus:border-none focus:shadow-none py-5'>
                            <div class="col-span-12 md:col-span-3 px-9 shadow-md p-7 rounded-lg bg-white hover:scale-105 transition duration-500 cursor-pointer">
                                <img src={Blog4} className='w-full h-[200px] rounded-[25px]' alt="" />
                                <div className="p-1 pt-4">
                                    <h3 className='font-bold'>Web solution</h3>
                                    <p className='text-sm py-3'>Promotion World has once placed Page Traffic the top.</p>
                                    <a href="#" className='text-sm  underline font-bold'>Read Blogs</a>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Blog;
