import React from 'react';

import './MarketingBrand.css'
import MarketingCard from './MarketingCard';
import CardImage1 from '../../asset/image/icon/1.png'
import CardImage2 from '../../asset/image/icon/2.png'
import CardImage3 from '../../asset/image/icon/3.png'
import CardImage4 from '../../asset/image/icon/4.png'
import CardImage5 from '../../asset/image/icon/5.png'
import CardImage6 from '../../asset/image/icon/6.png'
import CardImage7 from '../../asset/image/icon/7.png'
import CardImage8 from '../../asset/image/icon/8.png'


const MarketingBrand = () => {
    return (
        <div className='max-w-screen-2xl mx-auto my-20'>
            <div className="text-center max-w-screen-lg mx-auto">
                <h2 className='text-4xl font-bold'>Need help <span class="text-[#FE9901]">marketing and growing <br></br> your brand</span> online?</h2>
                <p className='text-xl pt-5'>We’re a full-service digital marketing firm that helps small to medium-sized businesses promote their online brand. Our affordable and customizable digital strategies deliver real results.</p>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-10">
              
              <MarketingCard img={CardImage7} title="Lead Generation" text="Drive business growth by attracting and converting qualified leads" color="bg-cardBG"/>
              <MarketingCard img={CardImage5} title="Social Media Marketing" text="Engage customers over social media platforms." color="bg-cardBG"/>
              <MarketingCard img={CardImage6} title="Online Ads" text="Design digital ad campaigns that fit your brand message." color="bg-cardBG"/>
              <MarketingCard img={CardImage8} title="Brand Design & Strategy" text="Stand out from the crowd with a unique brand identity" color="bg-cardBG"/>
              <MarketingCard img={CardImage4} title="Web Design Services" text="Develop a website that best represents your brand" color="bg-cardBG"/>
              <MarketingCard img={CardImage2} title="Reputation Management" text="Respond to reviews and grow your reputation." color="bg-cardBG"/>
              <MarketingCard img={CardImage3} title="Listings" text="Ensure your online business information is up-to-date." color="bg-cardBG"/>
              <MarketingCard img={CardImage1} title="SEO" text="Rank high with quality SEO tactics" color="bg-cardBG"/>

            </div>
        </div>
    );
};

export default MarketingBrand;