import React from 'react';
import Navbar from './Navbar';
import backgroundImage from '../../asset/image/background/headerBg.png';
import MarketingPageHeader from '../../asset/image/sectionImage/MarketingPageHeader.png';

const MarketingConsultantHeader = () => {

    const backgroundStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '70%',
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'center',
        height: '80%', // Adjust the height as needed
        width: '100%',
        margin: 'auto auto',
    };


    return (
        <div style={backgroundStyle}>
            <Navbar />

            <div class="max-w-screen-2xl mx-auto p-4 grid grid-cols-12 gap-1 pt-40">

                <div class="p-4 col-span-12 lg:col-span-7">
                    <h2 class="text-3xl md:text-4xl lg:text-5xl font-bold py-2 leading-tight lg:leading-normal">
                        Marketing<span class="text-[#FE9901]"> Consultant </span>
                    </h2>
                    <h6 class="text-xl md:text-2xl font-bold py-2 text-blue-900">
                        Business Marketing Agency » Marketing Consultant
                    </h6>
                    <p class="text-lg md:text-xl py-2 lg:pr-40">
                          At Business Marketing Agency, we understand that there is no on-size-fits-all-strategy or plan that we can use. Every brand and business has its own needs and goals that are to be met. Thus, we tailor plans that will fit to your business’s needs and deliver the best possible results.
                    </p>
                    <a href="#" class="flex items-center justify-center gap-2 px-6 py-3 mt-6 bg-[#FE9901] hover:bg-yellow-400 text-white font-bold rounded-full transition w-fit">
                        <span>Schedule a Consultation</span>
                        <svg class="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                        </svg>
                    </a>
                </div>


                <div class="p-4 col-span-12 lg:col-span-5 flex justify-center lg:justify-end">
                    <img src={MarketingPageHeader} class="w-full h-auto max-w-lg" alt="Header Image" />
                </div>
            </div>
        </div>
    );
};

export default MarketingConsultantHeader;