import React from 'react';
import Navbar from '../../component/Header/Navbar';
import ContactHeadderImage from '../../asset/image/ContactPage/Group 1707486823.png'
import ContactImage from '../../asset/image/ContactPage/Rectangle 1.png'
import Footer from '../../component/Footer/Footer';

const ContactPage = () => {
    return (
        <>

            <Navbar />

            {/* headdeer section */}
            <section className='max-w-screen-2xl mx-auto mt-[130px]'>
                <img src={ContactHeadderImage} className='w-full' alt="Blog Page Headder" />
            </section>

            {/* main blog section */}

            <main className='max-w-screen-2xl mx-auto py-20'>
                <section className='py-10 p-4'>
                    <p className='py-2 text-gray-500'>Contacting us is the first step towards unlocking your business’s full potential. Our dedicated team is ready to assist you with any inquiries, provide detailed information about our services, and guide you towards the best digital marketing solutions for your business.</p>
                    <p className='py-2 text-gray-500'>Whether you have questions, require a consultation, or want to discuss a potential project, we’re here to listen and provide personalized assistance. Reach out to us through the contact form, email, or phone, and let’s start a conversation that leads to your business’s success. We look forward to hearing from you!</p>
                </section>

                <section className='grid grid-cols-12 p-5'>
                    <div className="col-span-12 lg:col-span-6">
                        <img src={ContactImage} className='w-100 h-[100%]' alt="" />
                    </div>
                    <div className="col-span-12 lg:col-span-6">
                        <article className='shadow-lg bg-[#FEF5F4] rounded-[25px] lg:p-20 p-5 lg:ml-[-40px]'>
                            <div className='pb-8'>
                                <h2 className='text-2xl font-bold'>Send a Message</h2>
                                <p className='text-sm py-5'>Let your business soar beyond! We can’t wait to work with you!</p>
                                <h3 className='text-lg font-bold'>"*" indicates required fields</h3>
                            </div>

                            <form action="#" method="POST">
                                <div class="mb-6">
                                    <input class="border-0 bg-transparent shadow appearance-none  rounded w-full py-2 px-3 text-gray-700" id="first-name" type="text" placeholder="First Name" />
                                </div>
                                <div class="mb-6">
                                    <input class="border-0 bg-transparent shadow appearance-none rounded w-full py-2 px-3 text-gray-700" id="last-name" type="text" placeholder="Last Name" />
                                </div>
                                <div class="mb-6">
                                    <input class="border-0 bg-transparent shadow appearance-none rounded w-full py-2 px-3 text-gray-700" id="phone-number" type="text" placeholder="Phone Number" />
                                </div>
                                <div class="mb-6">
                                    <input class="border-0 bg-transparent shadow appearance-none rounded w-full py-2 px-3 text-gray-700" id="email" type="email" placeholder="Email" />
                                </div>
                                <div class="mb-6">
                                    <textarea class="border-0 bg-transparent shadow appearance-none rounded w-full py-2 px-3 text-gray-700" id="message" placeholder="Message"></textarea>
                                </div>
                                <div class="flex items-center justify-between">
                                    <button class="bg-[#FE9901] rounded-[25px] transition duration-500 text-white font-bold py-2 px-4 hover:bg-yellow-300" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </article>
                    </div>
                </section>
            </main>

            <Footer/>


        </>
    );
};

export default ContactPage;