import React from 'react';
import Navbar from '../../component/Header/Navbar';
import BlogPageHeadder from '../../asset/image/blog/BlogPageHeadder.png'
import Logo from '../../asset/image/logo.png'
import Footer from '../../component/Footer/Footer';
const BlogPage = () => {
    return (
        <>

            <Navbar />

            {/* headdeer section */}
            <section className='max-w-screen-2xl mx-auto mt-[130px]'>
                <img src={BlogPageHeadder} className='w-full' alt="Blog Page Headder" />
            </section>

            {/* main blog section */}

            <main className='max-w-screen-2xl mx-auto py-20'>
                <section className="grid grid-cols-12 gap-7">
                    <div className="col-span-12 lg:col-span-7">

                        <article className='px-10 py-5'>
                            <h3 className='text-2xl font-bold'>Enhance Your Business <span className='text-[#FE9901]'> Local Search Visibility</span></h3>
                            <p>Enhance Your Business Local Search Visibility –  Hello Business Owners, having a robust online presence is no longer optional for businesses—it’s essential. One of the most powerful yet often overlooked tools in a company’s digital arsenal is...</p>
                        </article>

                        <article className='px-10 py-5'>
                            <h3 className='text-2xl font-bold'>Hire a Digital Marketing Agency in <span className='text-[#FE9901]'> Melbourne to transform your Business</span></h3>
                            <p>Elevate Your Business with a Digital Marketing Agency in Melbourne Are you struggling to see tangible results from your marketing efforts? Many successful local business owners face this challenge. You have an excellent product and believe in what you offer, but...</p>
                        </article>

                        <article className='px-10 py-5'>
                            <h3 className='text-2xl font-bold'>Keyword Golden Ratio the <span className='text-[#FE9901]'> Secret Sauce to Skyrocket Your SEO</span></h3>
                            <p>Elevate Your Business with a Digital Marketing Agency in Melbourne Are you struggling to see tangible results from your marketing efforts? Many successful local business owners face this challenge. You have an excellent product and believe in what you offer, but...</p>
                        </article>

                        <article className='px-10 py-5'>
                            <h3 className='text-2xl font-bold'>The Google Antitrust Trial: What It Means for <span className='text-[#FE9901]'> Digital Marketing and SEO</span></h3>
                            <p>Google Antitrust Trial: How It Could Reshape Digital Marketing and SEO Hey there, digital marketers and SEO enthusiasts! If you’ve been keeping an eye on the tech world lately, you’ve probably heard about the big Google antitrust trial. But what’s it...</p>
                        </article>

                        <article className='px-10 py-5'>
                            <h3 className='text-2xl font-bold'>Instagram Ads vs Facebook Ads: Which Platform Will <span className='text-[#FE9901]'>S kyrocket Your Marketing in 2024?</span></h3>
                            <p>Instagram Ads vs Facebook Ads: The Ultimate Showdown for Social Media Marketing Success Hey there, social media marketers and business owners! Are you scratching your head trying to decide between Instagram Ads and Facebook Ads for your next campaign? Well,...</p>
                        </article>

                        <article className='px-10 py-5'>
                            <h3 className='text-2xl font-bold'>Geofencing Marketing: The Ultimate Guide to <span className='text-[#FE9901]'> Location-Based Advertising</span></h3>
                            <p>Geofencing Marketing: The Ultimate Guide to Location-Based Advertising Hey there, savvy marketer! Ready to take your advertising game to the next level? Then it’s time to dive into the world of geofencing marketing. This powerful location-based strategy is...</p>
                        </article>

                        <article className='px-10 py-5'>
                            <h3 className='text-2xl font-bold'>Google Business Profile: The Ultimate Guide to <span className='text-[#FE9901]'> Step-by-Step Guide to Mastering Marketing</span></h3>
                            <p>Unleash Your Small Business’s Potential: A Step-by-Step Guide to Master Marketing As a small business owner, you know that marketing can be a daunting task. With limited resources and a crowded marketplace, it can be challenging to stand out and attract the...</p>
                        </article>


                        <article className='px-10 py-5'>
                            <h3 className='text-2xl font-bold'>Digital Marketing for <span className='text-[#FE9901]'> Startups</span></h3>
                            <p>Digital Marketing for Startups: The Ultimate Guide to Growth and Success In today’s fast-paced digital world, startups face fierce competition and need every advantage they can get. Enter digital marketing – the game-changer that can propel your fledgling...</p>
                        </article>

                        <article className='px-10 py-5'>
                            <h3 className='text-2xl font-bold'>5 Essential Elements of Effective Content: A <span className='text-[#FE9901]'> Comprehensive Guide</span></h3>
                            <p>5 Essential Elements of Effective Content   In today’s digital landscape, content is king. But not just any content will do. To truly engage your audience and drive results, you need to master the art of crafting effective content. This comprehensive guide...</p>
                        </article>

                    </div>

                    <div className="col-span-12 lg:col-span-4 mx-auto">
                        <article className='bg-[#FEF4F3] p-10 rounded-[25px] border shadow-lg'>
                            <img src={Logo} className='w-44 mx-auto' alt="Logo" />
                            <div className="text-center py-4 text-[#212C65]">
                                <h2 className='text-xl font-bold'>Business Marketing Agency’s </h2>
                                <p className='text-sm font-bold tracking-widest'>OWNED BY YOU, DRIVEN BY US</p>
                            </div>

                            <div className="py-5 h-screen">
                                <ul className='font-bold text-sm uppercase flex flex-col gap-10 list-disc'>
                                    <li>Analytics and Measurement (3)</li>
                                    <li>Content Marketing (5)</li>
                                    <li>Local Advertising (5)</li>
                                    <li>Marketing Consultancy (8)</li>
                                    <li>Pay Per Click Advertising (5)</li>
                                    <li>Search Engine Optimization (11)</li>
                                    <li>Social Media Marketing (10)</li>
                                    <li>Website Design (3)</li>
                                </ul>
                            </div>
                        </article>
                    </div>
                </section>
            </main>
           
           <Footer/>

        </>
    );
};

export default BlogPage;