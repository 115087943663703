import React from 'react';
import logo from '../../asset/image/logo.png'
import Facebook from '../../asset/image/icon/facebook.png'
import Youtube from '../../asset/image/icon/youtube.png'
import Linkdin from '../../asset/image/icon/linkdin.png'
import Instagram from '../../asset/image/icon/instagram.png'
import X from '../../asset/image/icon/x.png'
import Bing from '../../asset/image/icon/bin.png'

const Footer = () => {
    return (
        <div className='bg-[#586893] py-10'>
             <div class="max-w-screen-2xl mx-auto grid grid-cols-12 lg:gap-x-10">
                <div class="col-span-12 md:col-span-6 lg:col-span-2 rounded-lg p-7">
                    <img src={logo} className='w-20' alt="" />
                    <div className='flex items-center gap-2 pt-4'>
                        <img src={Facebook} className='w-10' alt="" />
                        <img src={Instagram} className='w-10' alt="" />
                        <img src={Linkdin} className='w-10' alt="" />
                    </div>
                    <div className='flex items-center gap-2 pt-4'>
                        <img src={Youtube} className='w-10' alt="" />
                        <img src={Bing} className='w-10' alt="" />
                        <img src={X} className='w-10' alt="" />
                    </div>

                </div>

                <div class="col-span-12 md:col-span-6 lg:col-span-3 rounded-lg text-white p-7">
                    <h4 className='text-lg'>Pages</h4>
                    <ul className='pt-3'>
                        <li className="text-sm py-1">Marketing Consultant</li>
                        <li className="text-sm py-1">Marketing Services</li>
                        <li className="text-sm py-1">Who We Serve </li>
                        <li className="text-sm py-1">Areas Served</li>
                        <li className="text-sm py-1">Blogs</li>
                    </ul>

                </div>


                <div class="col-span-12 md:col-span-6 lg:col-span-3 rounded-lg text-white p-7">
                    <h4 className='text-lg'>Marketing Services</h4>
                    <ul className='pt-3'>
                        <li className="text-sm py-1">SEO Services Miami</li>
                        <li className="text-sm py-1">Reputation Management Miami</li>
                        <li className="text-sm py-1">Listings Management</li>
                        <li className="text-sm py-1">Website Design</li>
                        <li className="text-sm py-1">Social Media Marketing</li>
                    </ul>

                </div>

                <div class="col-span-12 md:col-span-6 lg:col-span-4 p-7 text-white">
                    <h4 className='text-lg'>Contact Details</h4>
                    <ul className='pt-3'>
                        <li className="text-sm py-1"><a href="#" className='flex items-center gap-2'><svg class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                            <span>2741 NE 30th Avenue
                                Lighthouse Point Fl 33064</span></a></li>
                        <li className="text-sm py-5"><a href="#" className='flex items-center gap-2'><svg class="h-6 w-6 text-white" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">  <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg><span>941 457 7427</span></a></li>
                        <li className="text-sm py-1"><a href="#" className='flex items-center gap-2'><svg class="h-6 w-6 text-white" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z" />  <rect x="3" y="5" width="18" height="14" rx="2" />  <polyline points="3 7 12 13 21 7" /></svg><span>success@businessmarketingagencyusa.com</span></a></li>


                    </ul>

                </div>

            </div>
        </div>
    );
};

export default Footer;